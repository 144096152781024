/* Custom functions on bottom /*

/* * * * * * * * * */
/* Base URL        */
/* * * * * * * * * */

fx_base = {
  _construct_main_container: function ($onlyVariables) {
    $footer_container = $("footer");
    $html_container = $("html");
    $smain_container = $(".smain-container");

    if (!$onlyVariables) {
      $smain_container.css({
        "min-height": "auto",
        height: "auto",
      });
    }

    footer_height = $footer_container.outerHeight();
    site_height = $html_container.outerHeight();
    smain_container_height = $smain_container.outerHeight();
    smain_container_real_height = $smain_container[0].scrollHeight;

    site_height_without_footer = site_height;
    site_height_with_footer = site_height - footer_height;
  },

  returnVariables: function () {
    this._construct_main_container(true);

    //--

    $(window).resize(function () {
      fx_base._construct_main_container(true);
    });

    //--

    return {
      footer_height: footer_height,
      site_height: site_height,
      site_height_without_footer: site_height_without_footer,
      site_height_with_footer: site_height_with_footer,
    };
  },

  getUrl: function () {
    pathArray = window.location.href.split("/");
    protocol = pathArray[0];
    host = pathArray[2];
    url = protocol + "//" + host;

    //--

    return url;
  },

  mainContainer: function () {
    /* This function defines the height of
      the main container by placing the footer
      at the bottom of the site, use the corresponding
      classes according to your needs. */

    this._construct_main_container();

    //--

    $smain_container // Default // Min height // With Footer
      .css("min-height", site_height_with_footer + "px");

    if ($smain_container.hasClass("min-full-screen"))
      // Min height // Without Footer
      $smain_container.css("min-height", site_height_without_footer + "px");

    if ($smain_container.hasClass("max-screen"))
      // Max height // With Footer
      $smain_container.css("height", site_height_with_footer + "px");

    if ($smain_container.hasClass("max-full-screen"))
      // Max height // Without Footer
      $smain_container.css("height", site_height_without_footer + "px");

    // Fixing absolute

    if (
      $smain_container.hasClass("max-screen") ||
      $smain_container.hasClass("max-full-screen")
    ) {
      if (
        smain_container_height <= smain_container_real_height &&
        site_height <= smain_container_real_height
      ) {
        $smain_container.css("min-height", smain_container_real_height);
      }
    }
  },
};

var baseUrl = fx_base.getUrl(); // -> Use This

/* * * * * * * * * */

/* * * * * * * * * */
/* messages        */
/* * * * * * * * * */

/*Errors/Modal of $errors variable*/

fx_messages = {
  _construct: function () {
    $messages = $(".messages");
    $btn_close = $messages.find(".close");
    $alert = $messages.find(".alert");
  },

  _hide: function (event) {
    event = typeof event !== "undefined" ? event : null;

    event.preventDefault();
    event.stopPropagation();

    $messages.stop().fadeOut();
  },

  make: function () {
    this._construct();

    //--

    $messages.on("click", function (event) {
      fx_messages._hide(event);
    });

    $alert.on("click", function (event) {
      event.stopPropagation();
    });

    $btn_close.on("click", function (event) {
      fx_messages._hide(event);
    });
  },
};

/* * * * * * * * * */

/* * * * * * * * * */
/* Proportionaly   */
/* * * * * * * * * */

/*If you are using squares or rectangles,
  these functions can help maintain the minimum
  or maximum proportion of the container to which
  the class is assigned.
  call fx_proportional.make() in the view where necessary*/

fx_proportional = {
  _construct: function () {
    $proportional = $(".proportional"); // -> Use this
  },

  _square: function () {
    $proportional.each(function (index) {
      $object = $(this);
      if ($object.hasClass("square")) {
        // -> Use this
        proportional_width = $object[0].getBoundingClientRect().width;
        $object.css("min-height", proportional_width + "px");
      }
    });

    $proportional.each(function (index) {
      $object = $(this);
      if ($object.hasClass("square-max")) {
        // -> Use this
        proportional_width = $object[0].getBoundingClientRect().width;
        $object.css("height", proportional_width + "px");
      }
    });
  },

  _rectangle: function () {
    $proportional.each(function (index) {
      $object = $(this);
      if ($object.hasClass("rectangle")) {
        // -> Use this
        proportional_width = Math.round(
          $object[0].getBoundingClientRect().width / 2
        );
        $object.css("min-height", proportional_width + "px");
      }
    });

    $proportional.each(function (index) {
      $object = $(this);
      if ($object.hasClass("rectangle-max")) {
        // -> Use this
        proportional_width = Math.round(
          $object[0].getBoundingClientRect().width / 2
        );
        $object.css("height", proportional_width + "px");
      }
    });
  },

  make: function () {
    this._construct();

    fx_proportional._square();
    fx_proportional._rectangle();

    $(window).resize(function () {
      fx_proportional._square();
      fx_proportional._rectangle();
    });
  },
};

/* * * * * * * * * * * */

/* * * * * * * * * */
/* Customs inputs  */
/* * * * * * * * * */

/*If you are using custom inputs or you need to validate any of these
  call fx_inputs.validate() in app.js inside of docReady function.*/

/** Inputs **/

fx_inputs = {
  _construct: function () {
    $fields = $("input, select, textarea");
    $only_numbers = $('input[type="only_number"]'); // Use only_numbers as type of input
  },

  _isEmpty: function ($input_field) {
    //-- active
    if ($input_field.val()) {
      $input_field.addClass("active");
    } else {
      $input_field.removeClass("active");
    }
    //--
  },

  _validateOnlyNumbers: function ($input_field, event) {
    var a = [];
    var k = event.which;

    for (i = 48; i < 58; i++) {
      // 0 - 9
      a.push(i);
    }

    a.push(13); // Enter
    a.push(46); // Comma
    a.push(44); // Dot

    if (!(a.indexOf(k) >= 0)) {
      event.preventDefault();
    }
  },

  validate: function () {
    this._construct();

    //--

    $only_numbers.keypress(function (event) {
      fx_inputs._validateOnlyNumbers($(this), event);
    });

    $fields.each(function (index) {
      fx_inputs._isEmpty($(this));
    });

    $fields.on("change", function (event) {
      fx_inputs._isEmpty($(this));
    });
  },
};

/* * * * * * * * * * * */

/* * * * * * * * * */
/* Symmetrical     */
/* * * * * * * * * */

/*If you want make symmetrical all items inside a container
  call fx_symmetrical.make() in the view where necessary*/

fx_symmetrical = {
  _construct: function () {
    $symmetrical_container = $(".symmetrical-container");
  },

  _symmetrical: function () {
    this._construct();

    //--

    $symmetrical_container.each(function (i) {
      a = 0;
      b = 0;

      $symmetrical = $(this).find(".symmetrical");

      $symmetrical.each(function (j) {
        $symmetrical.css("height", "auto");

        a = $(this).outerHeight();

        if (a >= b) {
          b = a;
        }

        $symmetrical.css("height", b + "px");
      });
    });
  },

  make: function () {
    fx_symmetrical._symmetrical();

    $(window).resize(function () {
      fx_symmetrical._symmetrical();
    });

    $(window).load(function () {
      fx_symmetrical._symmetrical();
    });
  },
};

/* * * * * * * * * * * */

// Function for two blocks on landing

fx_blocks_height = {
  _construct: function () {
    $pairs = $(".pairs");
    $html = $("html");
  },

  _setHeight: function (height, padding) {
    $pairs.css("min-height", height + padding);
  },

  _checkHeight: function () {
    site_height = fx_base.returnVariables().site_height_without_footer;

    // console.log( $html.outerWidth() );

    if ($html.outerWidth() > 767) {
      fx_blocks_height._setHeight(site_height, 0);
    } else {
      fx_blocks_height._setHeight(site_height / 2, 40);
    }
  },

  init: function () {
    this._construct();
    // --

    this._checkHeight();

    $(window).resize(function () {
      fx_blocks_height._checkHeight();
    });
  },
};

// Function for landing, giving the full height on one image

fx_setFullHeight = {
  _construct: function () {
    $target = $(".full-height");
    site_height = fx_base.returnVariables().site_height_without_footer;
  },

  _setHeight: function () {
    $target.css("height", site_height);
  },

  init: function () {
    this._construct();
    // --

    this._setHeight();

    $(window).resize(function () {
      fx_setFullHeight._setHeight();
    });
  },
};

// Function for the menu on responsive

fx_menu = {
  _construct: function () {
    $body = $("body");
    $smain_container = $(".smain-container");
    $main_menu = $(".main-menu");
    $trigger = $(".mobile-trigger");
    $nav_layer = $(".nav-layer");
    $els = $([])
      .add($body)
      .add($smain_container)
      .add($main_menu)
      .add($trigger)
      .add($nav_layer);
  },

  _showMenu: function () {
    $els.addClass("active");
  },

  _closeMenu: function () {
    $els.removeClass("active");
  },

  init: function () {
    this._construct();
    // --

    $trigger.on("click", function (event) {
      event.preventDefault();

      if (!$(this).hasClass("active")) {
        fx_menu._showMenu();
      } else {
        fx_menu._closeMenu();
      }
    });

    $nav_layer.on("click", function () {
      fx_menu._closeMenu();
    });
  },
};

// Slick Carousel

fx_slider = {
  _construct: function () {
    $slider = $(".slider");
    $slider_single = $(".slider--single");
    $slider_search = $(".slider-search");
  },
  search: function () {
    this._construct();
    // --

    $slider_search.slick({
      arrows: true,
      autoplay: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 993,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 769,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 601,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    });
  },

  single: function () {
    this._construct();
    // --

    $slider.slick({
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
    });
  },

  home: function () {
    this._construct();
    // --

    $slider.slick({
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
    });
  },
};

// Market Map

fx_map = {
  _construct: function () {
    map_content = $(".map")[0];
    icon = base_url + "/assets/img/ico/marker.png";
  },

  _createMap: function () {
    var location = { lat: 19.414052, lng: -99.164226 };

    var mapOptions = {
      zoom: 15,
      center: location,
      scrollwheel: false,
    };

    var map = new google.maps.Map(map_content, mapOptions);

    var marker = new google.maps.Marker({
      position: location,
      icon: icon,
      map: map,
    });

    google.maps.event.addDomListener(window, "resize", function () {
      map.setCenter(location);
    });
  },

  init: function () {
    this._construct();
    // --

    this._createMap();
  },
};

// Show Places per Category

fx_places = {
  _construct: function () {
    $trigger = $(".category-block");
    $category_zone = $(".places-from-category");
    $category_name = $(".category-name");
    category_name_el = ".category-block_name";
    $html_body = $("html, body");

    $category_all = $(".category-all");

    hash = location.hash;
  },

  _closeZone: function () {
    $category_zone.slideUp();
  },

  _moveToZone: function () {
    saveTop = $category_zone.offset().top;
    $html_body.animate({ scrollTop: saveTop - 100 }, "1000", "swing");
  },

  _openZone: function () {
    $category_zone.slideDown();
    fx_places._moveToZone();
    fx_symmetrical.make();
  },

  _setCategoryName: function ($this) {
    name = $this.find(category_name_el).text();
    $category_name.text(name);
  },

  _prepareZone: function ($this) {
    cat = $this.attr("data-category");

    blocks = $category_zone.find(".place-block");
    blocks.fadeOut();

    blocksToShow = blocks.filter("[data-category = " + cat + "]");
    blocksToShow.fadeIn();

    fx_places._openZone();
  },

  _prepareZoneAll: function () {
    blocks = $category_zone.find(".place-block");
    blocks.fadeIn();

    fx_places._openZone();
  },

  _setCategoryNameAll: function () {
    $category_name.text($category_all.attr("data-text"));
  },

  _beginFunctions: function ($this) {
    fx_places._closeZone();
    fx_places._prepareZone($this);
    fx_places._setCategoryName($this);
  },

  init: function () {
    this._construct();
    // --

    if (hash != "") {
      $el = $("[data-category='" + hash.substring(1) + "']");

      $(".category-block").removeClass("active");
      $el.addClass("active");

      if ($el.length > 0) {
        fx_places._beginFunctions($el);
      }
    }

    $category_all.click(function (event) {
      event.preventDefault();
      // --

      fx_places._prepareZoneAll();
      fx_places._setCategoryNameAll();
    });

    $trigger.click(function (event) {
      event.preventDefault();
      // --

      $(".category-block").removeClass("active");
      $(this).addClass("active");

      fx_places._beginFunctions($(this));
    });
  },
};

// Show Places per Category

fx_locals_map = {
  _construct: function () {
    $floors = $(".floor");
    $maps = $(".map");
    $locals = $(".local");
  },

  _selectMap: function (ref) {
    floor = ref.replace("#floor-", "");
    $map = $maps.filter("[data-floor=" + floor + "]");

    $map.nextAll().fadeOut();

    $map.prev(".layer-between").fadeIn();

    $map.fadeIn();

    fx_proportional.make();
  },

  _selectPins: function (ref) {
    floor = ref.replace("#floor-", "");
    $locals.fadeOut();
    $locals.filter("[data-floor=" + floor + "]").fadeIn();
  },

  init: function () {
    this._construct();

    $floors.on("click", function (e) {
      e.preventDefault();
      // --
      $floors.removeClass("active");
      $(this).addClass("active");

      if ($(this).attr("href") != "#!") {
        fx_locals_map._selectMap($(this).attr("href"));
        fx_locals_map._selectPins($(this).attr("href"));
      }
    });

    ref = $floors.filter(".active").attr("href");
    this._selectMap(ref);
    this._selectPins(ref);
  },
};

// Show Fancybox on Locals

fx_locals_fancybox = {
  _construct: function () {
    $locals = $(".local");
    $local_content = $("#local-content-lightbox");
  },

  init: function () {
    this._construct();
    // --

    $locals.each(function () {
      $(this).fancybox({
        beforeLoad: function () {
          this_img = this.opts.$orig.attr("data-img");
          this_url = this.opts.$orig.attr("data-href");
          $local_content.find("img").attr("src", this_img);
          $local_content.find("a").attr("href", this_url);
        },
      });
    });
  },
};

/** Instagram **/

fx_instagram = {
  _construct: function () {
    $instagram = $(".instagram");
    // $instagram_template = $("#instagram-template");
  },

  load: function () {
    this._construct();

    //--
    //
    var token = $instagram.attr("data-instagram-token");
    var feed = new Instafeed({
      accessToken: token,
      limit: 8,
      template:
        '<div class="scol-x-2--fit scol-s-6 scol-m-3 instagram-picture" style="height:200px"><a class="instagram-thumbnail" href="{{link}}" target="_blank" style="background-image: url({{ image }});"></a></div>',
    });
    feed.run();

    // $instagram.on("willLoadInstagram", function (event, options) {});

    // $instagram.on("didLoadInstagram", function (event, response) {
    //   var compiled = _.template($instagram_template.html());
    //   $(this).html(compiled({ items: response.data }));
    //   fx_proportional.make();
    // });

    // $instagram.instagram({
    //   userId: "1170284383",
    //   accessToken: "1170284383.ccec48e.8bcdeffa61b34e0db4d0641e8690c627",
    //   count: 8,
    // });
  },
};
